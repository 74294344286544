Cart = function() { Model.call(this); };

Cart.prototype             = new Model();
Cart.prototype.constructor = Cart;

// Add a vehicle to the cart
Cart.prototype.add_vehicle = function(make_id, model_id, year, callbacks)
{
    this.ajax({
        url:       'cart/vehicle',
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,

        data: {
            make_id:  make_id,
            model_id: model_id,
            year:     year
        }
    });
};

// Start a new cart with the specified vehicle
Cart.prototype.new_with_vehicle = function(make_id, model_id, year, callbacks)
{
    this.ajax({
        url:       'cart/new_with_vehicle',
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,

        data: {
            make_id:  make_id,
            model_id: model_id,
            year:     year
        }
    });
};

// Set the vin of a vehicle
Cart.prototype.set_vin = function(vehicle_id, vin, callbacks)
{
    this.ajax({
        url:       'cart/set_vehicle_vin/'+vehicle_id,
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,

        data: {
            vin: vin
        }
    });
};

// Delete a vehicle from the cart
Cart.prototype.delete_vehicle = function(vehicle_id, callbacks)
{
    this.ajax({
        url:       'cart/vehicle_delete',
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,

        data: {
            vehicle_id: vehicle_id
        }
    });
};

// Add an item to the cart
Cart.prototype.add_item = function(vehicle_id, part_type_code, callbacks)
{
    if (!vehicle_id || !part_type_code) {
        return;
    }

    this.ajax({
        url:       'cart/item/',
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,

        data: {
            vehicle_id:     vehicle_id,
            part_type_code: part_type_code
        }
    });
};

// Remove an item from the cart
Cart.prototype.delete_item = function(item_id, callbacks)
{
    this.ajax({
        url:       'cart/item_delete',
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,

        data: {
            item_id: item_id
        }
    });
};

// Add an application for an item, in the case of an aftermarket item, creates the stock
Cart.prototype.select_application = function(item_id, application_id, callbacks)
{
    this.ajax({
        url:       'cart/item/'+item_id,
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,

        data: {
            application_id: application_id
        }
    });
};

// Add a stock for an item
Cart.prototype.select_stock = function(item_id, stock_id, callbacks)
{
    this.ajax({
        url:       'cart/item/'+item_id,
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,

        data: {
            stock_id: stock_id
        }
    });
};

Cart.prototype.set_item_note = function(item_id, note, callbacks)
{
    this.ajax({
        url:       'cart/item/'+item_id,
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,

        data: {
            note: note
        }
    })
}

Cart.prototype.get_prices = function(callbacks)
{
    this.ajax({
        url:       'cart/prices',
        type:      'GET',
        dataType:  'json',
        callbacks: callbacks
    });
};

Cart.prototype.get_item = function(item_id, callbacks) {
    this.ajax({
        url:       'cart/get_item/'+item_id,
        type:      'GET',
        dataType:  'json',
        callbacks: callbacks
    });
};

// Save the additional information for the cart
Cart.prototype.save = function(note, date, purchase_order_number, callbacks)
{
    this.ajax({
        url:       'cart/save/',
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,

        data: {
            date: date,
            note: note,
            purchase_order_number: purchase_order_number
        }
    });
};

// Duplicate an item row
Cart.prototype.duplicate_item = function(item_id, callbacks)
{
    this.ajax({
        url:       'cart/duplicate_item',
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,
        data: { item_id: item_id }
    });
};

// Delete a cart
Cart.prototype.delete_cart = function(cart_id, callbacks)
{
    this.ajax({
        url:       'cart/delete/'+cart_id,
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks
    });
};

// Process Order
Cart.prototype.process_order = function(cart_id,callbacks)
{
	this.ajax({
        url:       'cart/process_order',
        type:      'POST',
        dataType:  'json',
        callbacks: callbacks,
        data: { cart_id: cart_id }
    });
};

Cart.prototype.get_missing_composites = function(stock_id, callbacks)
{
    this.ajax({
        url:       'search/missing_composites/'+stock_id,
        type:      'GET',
        dataType:  'json',
        callbacks: callbacks
    });
};

Cart.prototype.decode_vin = function(vehicle_vin, callbacks)
{
    this.ajax({
        url:       'vehicle/decode_vin/'+vehicle_vin,
        type:      'GET',
        dataType:  'json',
        callbacks: callbacks
    });
};
